<template>
  <div>
    <v-layout>
      <v-flex>
        <v-stepper v-model="step" elevation="0" rounded flat>
          <v-stepper-header>
            <v-stepper-step :complete="step > 1" step="1">
              Paciente
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="step > 2" step="2">
              Questionário
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="step > 3" step="3">
              Sequência
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="step > 4" step="4">
              Terapia
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="5">Diagnóstico</v-stepper-step>
          </v-stepper-header>
          <v-stepper-content class="pa-0 mt-1" step="1">
            <form-patient
              v-if="step === 1"
              @next="next"
              @back="back"
            ></form-patient>
          </v-stepper-content>
          <v-stepper-content class="pa-0 mt-1" step="2">
            <questions v-if="step === 2" @next="next" @back="back"></questions>
          </v-stepper-content>
          <v-stepper-content class="pa-0 mt-1" step="3">
            <sequences v-if="step === 3" @next="next" @back="back"></sequences>
          </v-stepper-content>
          <v-stepper-content class="pa-0 mt-1" step="4">
            <steps v-if="step === 4" @next="next" @back="back"></steps>
          </v-stepper-content>
          <v-stepper-content class="pa-0 mt-1" step="5">
            <diagnosis v-if="step === 5" @back="back"></diagnosis>
          </v-stepper-content>
        </v-stepper>
      </v-flex>
    </v-layout>
    <subscription-product-type-dialog />
  </div>
</template>

<script>
import db from "@/database";
export default {
  components: {
    "form-patient": () =>
      import(
        "@/domains/therapy/steps/patient/presentation/components/FormPatient"
      ),
    sequences: () =>
      import(
        "@/domains/therapy/steps/mount-sequence/presentation/components/Sequences"
      ),
    steps: () =>
      import("@/domains/therapy/steps/execute/presentation/components/Steps"),
    diagnosis: () =>
      import("@/domains/therapy/steps/diagnose/presentation/components/Main"),
    questions: () =>
      import(
        "@/domains/therapy/steps/questions/presentation/components/Questions"
      ),
    SubscriptionProductTypeDialog: () =>
      import(
        "@/domains/subscriptions/presentation/components/SubscriptionProductTypeDialog"
      ),
  },
  computed: {
    step: {
      get() {
        return this.$store.state.therapy.therapy.step;
      },
      set(value) {
        this.$store.dispatch("therapy/changeStep", value);
      },
    },
  },
  async mounted() {
    await this.syncOfflineData();
  },
  beforeDestroy() {
    this.$store.dispatch("therapy/saveTherapyOffline");
  },
  methods: {
    next() {
      this.step++;
    },
    back() {
      this.step--;
    },
    async syncOfflineData() {
      await this.$store.dispatch("sync/verifyIfNeedsUpdateAndDownload");
    },
  },
};
</script>

<style scoped></style>
